<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">安知课堂</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">政策快递管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="标题" class="searchboxItem ci-full">
              <span class="itemLabel">标题名称:</span>
              <el-input
                  v-model="title"
                  type="text"
                  clearable
                  size="small"
                  placeholder="请输入标题"
              />
            </div>
            <div title="发布状态" class="searchboxItem ci-full">
              <span class="itemLabel">发布状态:</span>
              <el-select size="small" clearable v-model="published" placeholder="请选择">
                <el-option :key="true" label="已发布" :value="true"></el-option>
                <el-option :key="false" label="未发布" :value="false"></el-option>
              </el-select>
            </div>
            <div title="终端" class="searchboxItem ci-full">
              <span class="itemLabel">终端:</span>
              <el-select size="small" style="width: 250px;" clearable multiple collapse-tags v-model="terminal" placeholder="请选择">
                <el-option v-for="item in policyTerminal" :label="item.label" :key="item.value" :value="item.value"></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" round @click="add()">新增</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                stripe
                :header-cell-style="tableHeader"
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
              />
              <el-table-column
                  label="标题"
                  align="left"
                  show-overflow-tooltip
                  prop="title"
                  min-width="160"
              />
              <el-table-column
                  label="是否推荐到首页"
                  align="center"
                  show-overflow-tooltip
                  prop="recommend"
                  min-width="140"
              >
                <template slot-scope="{ row }">
                  <el-switch
                      v-model="row.recommend"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-value="true"
                      :inactive-value="false"
                      @change="onRecommend(row)">
                  </el-switch>
                  <span :style="{'color':row.recommend?'#13ce66':'#ff4949','margin-left': '10px'}">{{row.recommend?'是':'否'}}</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="人气"
                  align="center"
                  show-overflow-tooltip
                  prop="viewCount"
              />
              <el-table-column
                  label="发布状态"
                  align="center"
                  show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{scope.row.published == true ? '已发布' : '未发布'}}
                </template>
              </el-table-column>
              <el-table-column
                  label="终端"
                  align="center"
                  show-overflow-tooltip
                  prop="terminal"
                  min-width="160"
              />
              <el-table-column
                  label="创建时间"
                  align="center"
                  show-overflow-tooltip
                  prop="createTime"
                  min-width="160"
              />
              <el-table-column label="操作" align="center" width="180px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                      style="padding: 0px 5px"
                      type="text"
                      size="mini"
                      @click="onPreview(scope.row)"
                  >预览</el-button
                  >
                  <el-button
                      style="padding: 0px 5px"
                      type="text"
                      size="mini"
                      @click="onEditor(scope.row)"
                  >编辑</el-button
                  >
                  <el-button
                      style="padding: 0px 5px"
                      type="text"
                      size="mini"
                      @click="onPublishRecycle(scope.row)"
                  >{{scope.row.published?'回收':'发布'}}</el-button
                  >
                  <el-button
                      style="padding: 0px 5px"
                      type="text"
                      size="mini"
                      @click="onDelete(scope.row)"
                  >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>
    <!-- 新增/编辑  弹窗 -->
    <el-dialog
        :title="dialogTitle"
        :visible.sync="centerDialogVisible"
        width="80%"
        center
        class="relation_jg_dialog"
        :close-on-click-modal="false"
        @close="doClose"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="8rem"
          class="demo-ruleForm"
          style="width: 90%"
      >
        <el-form-item label="标题名称：" prop="title">
          <el-input
              v-model="ruleForm.title"
              size="small"
              placeholder="请输入标题名称"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item label="政策分类：" prop="policyType">
          <el-select
              v-model="ruleForm.policyType"
              placeholder="请选择政策分类"
              size="small"
              clearable
          >
            <el-option
                v-for="item in policyTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="终端" prop="terminal">
          <el-checkbox-group v-model="ruleForm.terminal">
            <el-checkbox v-for="item in policyTerminal" :label="item.value" :key="item.value">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="是否推荐首页：" prop="recommend">
          <el-radio-group v-model="ruleForm.recommend">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
            label="封面图片"
            prop="coverUrl"
            :class="[rotationId ? 'item-disabled' : '']"
            required
        >
          <el-upload
              :on-change="handleAvatarSuccess2"
              :before-upload="beforeAvatarUpload2"
              :http-request="$requestMine"
              :show-file-list="false"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-image
                :src="ruleForm.coverUrl || require('@/assets/develop.png')"
                fit="contain"
                class="imgCenter"
            ></el-image>
          </el-upload>
          <!--          <p-->
          <!--              style="color:#f46173;line-height:25px;"-->
          <!--          >请上传 640*357 （长 X 宽）像素的PNG,JPG格式的图片，且文件大小不能超过1MB！</p>-->
        </el-form-item>

        <el-form-item label="内容：" prop="content" class="form-item" style="overflow: hidden;position:relative;">
          <span style="color: red;position:absolute;top: 0;left: -63px">*</span>
          <div ref="editor" class="editor" style="width:100%;max-width:1500px" />

          <span v-show="descriptionTitle" class="showtitle"
          >请输入测评详情</span
          >


        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: center">
        <el-button @click="doClose()">取消</el-button>
        <el-button class="bgc-bv" @click="submitForm('ruleForm')">
          确定
        </el-button>
      </div>
    </el-dialog>
    <!--预览-->
    <el-dialog
        title="预览"
        :visible.sync="previewDialogVisible"
        top="3%"
        width="50%"
        center
        class="relation_jg_dialog"
        :close-on-click-modal="false"
    >
      <div class="shopDecorate">
        <div class="flexjc">
          <div class="phonebox flexdc">
            <div class="phone_top">
              <div class="flexText">
                政策详情
              </div>
              <div class="flexcb setBox">
                <i class="el-icon-more"></i>
                <el-divider direction="vertical"></el-divider>
                <i class="el-icon-remove-outline"></i>
              </div>
            </div>
            <div
                style="min-height: 634px;overflow-y: scroll"
                class="phone_content"
                ref="appbox"
            >
<!--              <div-->
<!--                  class="phone_banner df"-->
<!--                  id="banner"-->
<!--              >-->
<!--                <el-image-->
<!--                    style="width: 100%; height: 150px"-->
<!--                    :src="policyInfo.coverUrl"-->
<!--                    :fit="fill"></el-image>-->

<!--              </div>-->
              <div style="text-align: center;font-weight: bold;font-size: 16px">{{ policyInfo.title }}</div>
              <div style="text-align: center;">{{ policyInfo.createTime }}</div>
              <div v-html="policyInfo.content"></div>
            </div>
            <div class="phone_bot flexca flexac">
              <span style="font-weight: 700">首页</span>
              <span>学习</span>
              <span>题库</span>
              <span>我的</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import E from "wangeditor";
import UploadPic from "@/mixins/UploadPic";
import { resetKeepAlive,validateURL } from "@/utils/common";
let editor = {};
export default {
  name: "policyExpressList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List,UploadPic],
  data() {
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系电话"));
      } else if (
          value &&
          !/^1[3456789]\d{9}$/.test(value) &&
          !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的联系电话"));
      } else {
        callback();
      }
    };
    return {
      title: "", //问卷名称
      published: "", //发布状态
      terminal: "", //终端
      centerDialogVisible: false,
      dialogTitle: "", // 弹框的标题
      ruleForm: {
        policyId: "", // 政策快递ID
        title: "", // 标题名称
        policyType: "", // 政策分类
        recommend: false, // 是否推荐首页
        terminal: [],// 终端
        coverUrl: "", // 封面
        coverKey: "", // 封面key
        content: '',// 内容
      },
      previewDialogVisible: false,
      policyInfo: {},//预览
      policyTypeList: [],
      policyTerminal: [],
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        policyType: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
        recommend: [
          { required: true, message: "请选择是否推荐首页", trigger: "change" },
        ],
        coverUrl: [
          { required: true, message: "请上传封面", trigger: "change" }
        ],
        terminal: [
          { type: 'array', required: true, message: '请至少选择一个终端', trigger: 'change' }
        ],
      },

      descriptionTitle: false
    };
  },
  computed: {},
  mounted() {
    this.getPolicyTypeList()
  },
  created() {
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    // 获取政策分类标识--码值
    getPolicyTypeList() {
      const PolicyType = this.$setDictionary("HR_POLICY_TYPE", "list");
      for (const key in PolicyType) {
        this.policyTypeList.push({
          value: key,
          label: PolicyType[key],
        });
      }
      const POLICYTERMINAL = this.$setDictionary("HR_POLICY_TERMINAL", "list");
      for (const key in POLICYTERMINAL) {
        this.policyTerminal.push({
          value: key,
          label: POLICYTERMINAL[key],
        });
        console.log(this.policyTerminal);
      }
    },
    // 图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
          ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
              res.raw.type
          ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
            .then(result => {
              this.ruleForm.coverUrl = result.data.fileURL || "";
              this.ruleForm.coverKey = result.data.fileKey || "";
            })
            .catch(() => {
              setTimeout(() => {
                this.$message({
                  type: "warning",
                  message: "上传失败"
                });
              }, 300);
            });
      }
    },
    // 富文本
    editorInit(content) {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo" // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = false; //富文本编辑器中点击图片上传选择图片后激发的事件：
      let _this = this;
      editor.config.customUploadImg = function(files, insert) {
        //对上传的图片进行处理，图片上传的方式
        for (let i = 0; i < files.length; i++) {
          let formData = new FormData();
          formData.append("folder ", "COURSE_WARE");
          formData.append(
              "fileType ",
              files[i].name.substring(files[i].name.lastIndexOf(".") + 1)
          );
          formData.append("isPublic ", true);
          formData.append("file", files[i]);
          _this.$Postformat("/sys/upload", formData).then(result => {
            insert(result.data.fileURL);
          });
        }
      };
      editor.config.onchange = html => {
        this.ruleForm.content = html;
      };
      editor.config.pasteTextHandle = function(content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      if(content){
        editor.txt.html(content);
        this.ruleForm.content = content

      }

      // const eWidth = this.$refs.editor.clientWidth;
      // this.eWidth = eWidth;
    },
    // 新增
    add() {
      this.centerDialogVisible = true;
      this.dialogTitle = "新增政策快递";
      this.$nextTick(()=>{
        this.editorInit();
      })
    },
    // 修改
    onEditor(row) {
      this.$post("/biz/hr_evaluate/hr-policy/getInfo", {
        policyId: row.policyId,
      },3000,true,6).then((ret) => {
        if(ret.status == 0){
          console.log(ret);
          this.ruleForm = {
            ...ret.data,
            terminal:ret.data.terminal.split(",")
          }
          this.centerDialogVisible = true;
          this.dialogTitle = "编辑政策快递";
          this.$nextTick(()=>{
            this.editorInit(ret.data.content);

          })
        }
      });
    },
    // 预览
    onPreview(row){
      this.$post("/biz/hr_evaluate/hr-policy/getInfo", {
        policyId: row.policyId,
      },3000,true,6).then((ret) => {
        if(ret.status == 0){
          console.log(ret.data)
          this.policyInfo = {
            ...ret.data
          }
          this.policyInfo.createTime = this.policyInfo.createTime.substring(0,10)
          this.previewDialogVisible = true;
        }
      });
    },
    // 发布回收
    onRecommend(row){
      this.$post("/biz/hr_evaluate/hr-policy/recommend", {
        policyId: row.policyId,
      },3000,true,6).then((ret) => {
        this.$message({
          type: "success",
          message: '操作成功！',
        });
        this.getData(-1);
      });
    },
    // 发布回收
    onPublishRecycle(row){
      this.$post("/biz/hr_evaluate/hr-policy/publish", {
        policyId: row.policyId,
      },3000,true,6).then((ret) => {
        this.$message({
          type: "success",
          message: row.published?'回收成功':'发布成功!',
        });
        this.getData(-1);
      });
    },
    // 删除
    onDelete(row) {
      this.$confirm("此操作将永久删除该政策快递, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
          .then(() => {
            this.$post("/biz/hr_evaluate/hr-policy/delete", {
              policyId: row.policyId,
            },3000,true,6).then((ret) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getData(-1);
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    // 获取数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if(this.title) {
        params.title = this.title
      }
      if(this.published === true||this.published === false) {
        params.published = this.published
      }
      if(this.terminal) {
        params.terminal = this.terminal.join(",")
      }
      this.doFetch({
        url: "/biz/hr_evaluate/hr-policy/pageList",
        params,
        pageNum,
      },true,6);
    },
    // 关闭 - 新增&编辑弹窗
    doClose() {
      this.centerDialogVisible = false;
      this.$refs["ruleForm"].resetFields();
      // 对象回到初始值
      this.ruleForm = this.$options.data().ruleForm;
      this.descriptionTitle = false
      editor.destroy()
    },
    // 确认
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(!this.ruleForm.content.includes('img')){
            let str = this.ruleForm.content.replace(/<[^>]+>/g, "");
            str.replace(/\s*/g, "");
            if (!str) {
              this.descriptionTitle = true
              return false;
            }else{
              this.descriptionTitle = false
              this.doAjax();
            }
          } else {
            this.doAjax();
          }
        }
      });
    },
    // 确定 - 方法
    doAjax() {
      let url = "/biz/hr_evaluate/hr-policy/insert";
      let params = {
        title: this.ruleForm.title,
        policyType: this.ruleForm.policyType,
        recommend: this.ruleForm.recommend,
        coverUrl: this.ruleForm.coverUrl,
        coverKey: this.ruleForm.coverKey,
        content: this.ruleForm.content,
        terminal: this.ruleForm.terminal.join(","),
      };
      if (this.ruleForm.policyId) {
        params.policyId = this.ruleForm.policyId;
        url = "/biz/hr_evaluate/hr-policy/modify";
      }
      this.$post(url, params,3000,true,6)
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.doClose();
              this.getData(-1);
            }
          })
          .catch((err) => {
            return;
          });
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: relative;
  top: 100%;
  left: 0;
}
.shopDecorate {
  .img-el-upload {
    width: 80px;
    height: 50px;
    .el-upload {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .el-switch__core {
    width: 45px !important;
  }
}
.shopDecorate {
  .phone_top {
    padding: 20px 13px 0;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .setBox {
      border: 1px solid #dcdfe6;
      padding: 5px 8px;
      border-radius: 20px;
    }
  }
  .phone_bot {
    border-top: #000 solid 1px;
    padding: 10px 13px 20px;
  }

  .phonebox {
    .top {
      position: relative;
      z-index: 100;
      cursor: pointer;
    }
    width: 375px;
    min-height: 500px;
    height: 750px;
    border: 1px solid #000;
    border-radius: 40px;
    > .phone_content {
      padding: 0 13px;
      margin-bottom: 10px;
      > div {
        position: relative;
        margin-bottom: 10px;
      }
    }
    .phone_banner {
      min-height: 150px;
      text-align: center;
      background-color: #999;
    }
    .phone_application {
      width: 100%;
      min-height: 115px;
      // height: auto;
      .application_item {
        width: 25%;
        margin-bottom: 5px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-bottom: 10px;
        }
      }
    }
    .pushBanner {
      width: 100%;
      height: 120px;
    }
    .Selected {
      // min-height: 300px;
      .course_list {
        padding: 10px 0;
        img {
          width: 100px;
          height: 70px;
          margin-right: 8px;
          flex-shrink: 0;
        }
      }
    }
    .workPlace {
      padding: 10px 5px 20px;
      .course_list {
        padding: 10px 0;
        img {
          width: 100px;
          height: 70px;
          margin-right: 8px;
          flex-shrink: 0;
        }
      }
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(203, 209, 255, 0.4);
    z-index: 99;
    width: 100%;
    height: 100%;
    border: 2px dashed #001bff;
    display: flex;
    justify-content: flex-end;
    div {
      align-self: flex-end;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0 5px;
      }
    }
  }
}
.flexText{
  margin-left: 64px;
  width: 212px;
  text-align: center;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
}
/deep/ .el-select__tags{
   .el-tag{
      min-width: 0!important;
    .el-select__tags-text{
      min-width: 0!important;
    }
  }
}
</style>